/* General Container */
.input-file-container {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.input-file-container input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

/* Custom Label for File Input */
.input-file-container label {
  display: inline-block;
  background-color: #007bff; /* Primary color */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.input-file-container label:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Display the chosen file name */
.input-file-container .file-name {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  word-wrap: break-word;
}
